.container {
  .textRoot {
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 5px;
    max-width: 350px;
  }

  .textInput {
    font-size: 'app-font';
    font-size: 14px;
    color: #2d3748;
    padding: 16px 20px;
  }

  .button {
    border-radius: 5px;
    color: #ffffff;
    min-width: 350px;
  }

  .errorMsg {
    max-width: 350px;
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem text-sm py-1 text-red-600;
  }
}
