.container {
  //   gap: 24px;
  .textRoot {
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 5px;
    max-width: 350px;
  }
  .textInput {
    font-size: 'app-font';
    font-size: 14px;
    color: #2d3748;
    padding: 16px 20px;
  }
  .button {
    border-radius: 5px;
    margin-top: 24px;

    color: #ffffff;
    min-width: 350px;
    background-color: #1d31e2;
  }

  .errorMsg {
    max-width: 350px;
  }
}
