.container {
  position: absolute;
  top: 35%;
  left: 15%;
  right: 15%;
  .textRoot {
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 5px;
    max-width: 350px;
  }

  .textInput {
    font-size: 'app-font';
    font-size: 14px;
    color: #2d3748;
    padding: 16px 20px;
  }

  .forgotButton {
    color: #1d31e2;
    margin: 0;
    border-radius: 7px;
    padding: 4px;
  }

  .heading {
    max-width: 700px;
    font-size: 24px;
    color: #868686;
    margin-left: 20px;
    text-align: center;
  }
}
