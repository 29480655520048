.container {
  margin-top: 50px;
  .textRoot {
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 5px;
    max-width: 400px;
  }
  .textInput {
    font-size: 'app-font';
    font-size: 14px;
    color: #2d3748;
    padding: 16px 20px;
  }
  .button {
    margin-top: 24px;
    border-radius: 5px;
    min-width: 400px;
  }

  .errorMsg {
    max-width: 350px;
  }

  .confirmEmailContainer {
    font-size: 24px;
    text-align: center;
  }

  .confirmText {
    margin-top: 20px;
    text-align: center;
    color: #7b7b7b;
  }
}
