.container {
  .createButton {
    color: #1d31e2;
    margin-top: 17px;
    margin-left: 15px;
    border-radius: 7px;
  }
}
region.wavesurfer-region:before {
  content: attr(data-region-label);
  position: absolute;
  top: 0;
}
