.container {
  margin-top: 9.375rem;
  .textRoot {
    //border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 5px;
  }
  .createAccount.createAccount {
    width: 200px;
  }
  .textInput {
    font-size: 'app-font';
    font-size: 14px;
    color: #2d3748;
    padding: 16px 20px;
  }

  .button {
    border-radius: 5px;
    color: #ffffff;
    min-width: 350px;
    background-color: #1d31e2;
  }

  .forgotButton {
    border-radius: 5px;

    margin-top: 12px;
    margin-left: -6px;
    width: fit-content;
    font-weight: 300;
    &:hover {
      background-color: #e1c7f65e;
    }
    transition: ease 0.5s;
  }

  .error {
    max-width: 350px;
  }

  .createButton {
    color: #1d31e2;
    margin-top: 17px;
    margin-left: 15px;
    border-radius: 7px;
  }
}
